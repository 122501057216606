import Button from '../components/Button/Button';
import { Row } from '@lightningjs/solid-ui';

const ButtonsPage = () => {
  function onEnter(event, elm) {
    this.states.toggle('disabled');
  }

  const RowStyles = {
    display: 'flex',
    justifyContent: 'flexStart',
    width: 1500,
    height: 300,
    color: '00000000',
    gap: 26,
    y: 400,
    x: 100
  }

  const RowStyles2 = {
    display: 'flex',
    justifyContent: 'flexStart',
    width: 1500,
    height: 300,
    color: '00000000',
    gap: 26,
    y: 10,
    x: 100
  }

  return (
    <>
      <Row style={RowStyles2}>
        <Button autofocus onEnter={onEnter}>Focused</Button>
        <Button states={{ active: true, disabled: false }}>Normal</Button>
        <Button states='disabled'>Disabled</Button>
      </Row>

      <Row style={RowStyles}>
        <Button autofocus onEnter={onEnter}>Focused</Button>
        <Button states={{ active: true, disabled: false }}>Normal</Button>
        <Button states='disabled'>Disabled</Button>
      </Row>

    </>

  );
};

export default ButtonsPage;
