import { createEffect, createSignal } from 'solid-js';

import { Text, View } from '@lightningjs/solid';
//import { Title } from "@lightningjs/solid-ui";
import { Badge, Label, Metadata, Tile } from '@lightningjs/solid-ui';
import { Row } from '@lightningjs/solid-ui';


const LiveTvPage = () => {
  

  const [items, setItems] = createSignal([]);

  // createEffect to handle fetching data from the API when the component is first mounted
  createEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://smarters.daznedge.com/player_api.php?username=12CCSP0924TV1&password=heGYRMMq&action=get_live_streams');
        const data = await response.json();
        // Adjust this according to the actual response structure
        console.log("============================ ",data);
        setItems(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  });




  return (

    <>

      <Row
        gap={20}
        selected={100}
        autofocus
        x={150}
        y={50}
        transition={{ x: { duration: 350 } }}
      >
        <For each={items()}>
          {(item, index) => (
            <View
              width={100}
              height={200}
              style={{ color: 0xff0000ff, focus: { color: 0xffffffff } }}
              src={"https://m.media-amazon.com/images/M/MV5BZTliNWJhM2YtNDc1MC00YTk1LWE2MGYtZmE4M2Y5ODdlNzQzXkEyXkFqcGdeQXVyMzY0MTE3NzU@._V1_FMjpg_UX1000_.jpg"}
            />
          )}
        </For>

      </Row>

      <Row
        gap={20}
        selected={0}
        
        x={150}
        y={500}
        transition={{ x: { duration: 350 } }}
      >


        <For each={items()}>
          {(item, index) => (
            <View
              width={100}
              height={200}
              style={{ color: 0xff0000ff, focus: { color: 0xffffffff } }}
              src={"https://lumiere-a.akamaihd.net/v1/images/p_coco_19736_fd5fa537.jpeg"}
            />
          )}
        </For>
        


      </Row>

      
    </>
  );

  
};

export default LiveTvPage;
